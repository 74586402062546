import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

const modalAdapter = createEntityAdapter();

const initialState = modalAdapter.getInitialState({
  isClose: true,
  activeCategory: null
});

const searchPanelSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.isClose = !state.isClose;
      state.activeCategory = action.payload || null
    },
  },
});

const { actions, reducer } = searchPanelSlice;

export default reducer;

export const { openModal } = actions;
