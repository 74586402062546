import { PropagateLoader } from "react-spinners";
import {
  useGetAllExchangersQuery,
  useDeleteExchangerMutation,
} from "../../api/exchanger";

import ErrorComponent from "../error/Error";
import Table from "../table/Table";

const ExchangerTable = () => {
  const {
    data: exchangers = [],
    isLoading,
    isError,
  } = useGetAllExchangersQuery();
  const [deleteExchanger] = useDeleteExchangerMutation();

  if (isLoading) {
    return (
      <div className="spinner__wrapper">
        <PropagateLoader
          className="spinner"
          color={"#9760ad"}
          loading={true}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  } else if (isError) {
    return <ErrorComponent />;
  }

  const handleRequests = async (username) => {
    await deleteExchanger({ username });
  };

  const renderRows = exchangers.map(({ exchanger_id, title, username }) => (
    <tr key={exchanger_id}>
      <td className="table__content">{exchanger_id}</td>
      <td className="table__content">{title}</td>
      <td className="table__content">@{username}</td>
      <td>
        <button
          onClick={() => handleRequests(username)}
          className="toggle_button false"
        >
          Удалить
        </button>
      </td>
    </tr>
  ));

  return (
    <Table
      title={"Обменники"}
      headers={[
        { label: "ID", isHide: false },
        { label: "Название", isHide: false },
        { label: "Username", isHide: false },
        { label: "Дейсвтие", isHide: false },
      ]}
      rows={renderRows}
    />
  );
};

export default ExchangerTable;
