import "./notFoundPage.scss";

const NotFoundPage = () => {
  return (
    <>
      <h1>
        Error <span className="errorcode">404</span>
      </h1>
    </>
  );
};

export default NotFoundPage;
