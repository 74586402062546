const stringConverter = (str) => {
  if (str === null) {
    return "null";
  }
  return str.length > 25 ? str.slice(0, 21) : str;
};

const convertDateTime = (time) => {
  const date = new Date(time);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Місяці в JavaScript починаються з 0
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

export { stringConverter, convertDateTime };
