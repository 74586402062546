import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { backUrl } from "../config";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({ baseUrl: backUrl }),
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: () => "users",
      providesTags: ["Users"],
    }),
    getAllEmployees: builder.query({
      query: () => "users/employees",
      providesTags: ["Employees"],
    }),
    updateUserBalance: builder.mutation({
      query: ({ user_id, value, accessKey }) => ({
        url: "users/update_balance",
        method: "POST",
        body: { user_id, balance: value, accessKey },
      }),
      invalidatesTags: ["Users"],
    }),
    updateUserDiscount: builder.mutation({
      query: ({ user_id, value }) => ({
        url: "users/update_discount",
        method: "POST",
        body: { user_id, discount: value },
      }),
      invalidatesTags: ["Users"],
    }),
    updateUserVip: builder.mutation({
      query: ({ user_id, value }) => ({
        url: "users/update_vip",
        method: "POST",
        body: { user_id, vip: value },
      }),
      invalidatesTags: ["Users"],
    }),
    updateUserBan: builder.mutation({
      query: ({ user_id, value }) => ({
        url: "users/update_ban",
        method: "POST",
        body: { user_id, ban: value },
      }),
      invalidatesTags: ["Users"],
    }),
    updateUserRole: builder.mutation({
      query: ({ user_id, value }) => ({
        url: "users/update_role",
        method: "POST",
        body: { user_id, role: value },
      }),
      invalidatesTags: ["Employees"],
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useGetAllEmployeesQuery,
  useUpdateUserBalanceMutation,
  useUpdateUserDiscountMutation,
  useUpdateUserVipMutation,
  useUpdateUserBanMutation,
  useUpdateUserRoleMutation,
} = userApi;
