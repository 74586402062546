import { useEffect } from "react";

import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateAccessKey } from "../../features/accessKey";

import { useAuth } from "../../hooks/useAuth";

import "./mainPage.scss";

const MainPage = () => {
  const { accessKey } = useParams();
  const currentAccessKey = useSelector((state) => state.access.accessKey);
  const dispatch = useDispatch();

  useEffect(() => {
    if (accessKey && accessKey !== currentAccessKey) {
      dispatch(updateAccessKey(accessKey));
    }
  }, [accessKey, currentAccessKey, dispatch]);

  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return;
  }

  return (
    <header className="header">
      <h2 className="header__title">
        Добро пожаловать в админ панель Вашего телеграмм бота
      </h2>
      <p className="header__description">
        Восспользуйтесь навигационным меню для отображения нужной Вам страницы
      </p>
    </header>
  );
};

export default MainPage;
