import { useDispatch, useSelector } from "react-redux";


import { activeFilterChanged } from "./searchPanelSlice";
import "./searchPanel.scss"

const SearchPanel = () => {
  const {activeFilter} = useSelector((state) => state.filters);

  const dispatch = useDispatch();

  const btnsData = [
    { name: "all", label: "Всех" },
    { name: "vip", label: "VIP" },
    { name: "discount", label: "Скидка" },
    { name: "banned", label: "Бан" },
  ];

  const buttons = btnsData.map(({ name, label }, index) => (
    <li className="search-panel__filter-item" key={index}>
      <button
        className={`search-panel__button ${
          name === activeFilter ? "search-panel__button-active" : ""
        } `}
        onClick={() => dispatch(activeFilterChanged(name))}
      >
        {label}
      </button>
    </li>
  ));

  return (
    <section className="search-panel">
      <input
        onChange={(e) => dispatch(activeFilterChanged(e.target.value))}
        className="search-panel__input"
        type="text"
        placeholder="Введите ID пользователя для поиска."
      />
      <div className="search-panel__filters">
        <h3 className="search-panel__filters-title">Выберите фильтр:</h3>
        <ul className="search-panel__buttons-wrapper">{buttons}</ul>
      </div>
    </section>
  );
};

export default SearchPanel;
