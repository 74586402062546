import { useSelector } from "react-redux";

// import PageHeader from "../components/pageHeader/PageHeader";
import SettingsForm from "../components/settingsForm/SettingsForm";
import EmployeesTable from "../components/employeesTable/EmployeesTable";
import EmployeeAddForm from "../components/employeeAddForm/EmployeeAddForm";
import SettingsHeader from "../components/settingsHeader/SettingsHeader";
import TextSettingsForm from "../components/textSettingsForm/TextSettingsForm";
import ExchangerTable from "../components/exchangerTable/ExchangerTable";
import ExchangerAddForm from "../components/exchangerAddForm/ExchangerAddForm";
import ErrorComponent from "../components/error/Error";

const SettingsPage = () => {
  const { activeSettings } = useSelector((state) => state.settings);
  let pageComponent = null;
  switch (activeSettings) {
    case "settings":
      pageComponent = <SettingsForm />;
      break;
    case "employees":
      pageComponent = (
        <>
          <EmployeesTable />
          <EmployeeAddForm />
        </>
      );
      break;
    case "textSettings":
      pageComponent = <TextSettingsForm />;
      break;
    case "exchangers":
      pageComponent = (
        <>
          <ExchangerTable />
          <ExchangerAddForm />
        </>
      );
      break;

    default:
      pageComponent = (
        <ErrorComponent errorMessage={"Выберите нужный вам пункт подменю!"} />
      );
  }

  const menuData = [
    { name: "settings", label: "Настройки" },
    { name: "employees", label: "Сотрудники" },
    { name: "textSettings", label: "Текста" },
    { name: "exchangers", label: "Обменники" },
  ];

  return (
    <>
      <SettingsHeader menuData={menuData} />
      {pageComponent}
    </>
  );
};

export default SettingsPage;
