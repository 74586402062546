import { useState } from "react";
import {
  Formik,
  Form,
  Field,
  ErrorMessage as FormikErrorMessage,
} from "formik";
import * as Yup from "yup";

import { useAddNewExchangerMutation } from "../../api/exchanger";

import "../employeeAddForm/employeeAddForm.scss";

const ExchangerAddForm = () => {
  const [addNewExchanger] = useAddNewExchangerMutation();
  const [buttonText, setButtonText] = useState("Добавить");
  const [buttonClass, setButtonClass] = useState("settings__button");

  return (
    <section className="employee">
      <h3 className="employee_title table__title">Добавить обменник</h3>
      <Formik
        initialValues={{
          title: "",
          username: "",
        }}
        validationSchema={Yup.object({
          title: Yup.string()
            .max(128, "Не больше 128 символов")
            .required("Это обязательное поле!"),
          username: Yup.string().required("Это обязательное поле!"),
        })}
        onSubmit={async ({ title, username }, { resetForm }) => {
          await addNewExchanger({ title, username });
          resetForm();
          setButtonText("Добавлено!");
          setButtonClass("settings__button success");
          setTimeout(() => {
            setButtonText("Сохранить");
            setButtonClass("settings__button");
          }, 500);
        }}
      >
        <Form className="form">
          <div className="form__wrapper">
            <Field
              className="form__field"
              name="title"
              placeholder="Введите название обменника"
            />
            <FormikErrorMessage
              component="div"
              name="title"
              className="form__error-message"
            />
          </div>
          <div className="form__wrapper">
            <Field
              className="form__field"
              name="username"
              placeholder="Введите юзер обменника без @"
            />
            <FormikErrorMessage
              component="div"
              name="username"
              className="form__error-message"
            />
          </div>

          <button type="submit" className={`${buttonClass}`}>
            {buttonText}
          </button>
        </Form>
      </Formik>
    </section>
  );
};

export default ExchangerAddForm;
