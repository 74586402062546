import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { backUrl } from "../config";


export const exchangerApi = createApi({
  reducerPath: "exchangerApi",
  baseQuery: fetchBaseQuery({ baseUrl: backUrl }),
  endpoints: (builder) => ({
    getAllExchangers: builder.query({
      query: () => "exchangers",
      providesTags: ["Exchangers"],
    }),
    addNewExchanger: builder.mutation({
      query: ({ title, username }) => ({
        url: "exchangers/new",
        method: "POST",
        body: { title, username },
      }),
      invalidatesTags: ["Exchangers"]
    }),
    deleteExchanger: builder.mutation({
        query: ({ username }) => ({
          url: "exchangers/delete",
          method: "POST",
          body: { username },
        }),
        invalidatesTags: ["Exchangers"]
      }),
  }),
});

export const { useGetAllExchangersQuery, useAddNewExchangerMutation, useDeleteExchangerMutation } = exchangerApi;
