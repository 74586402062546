import { useState, useEffect } from "react";

import {
  Formik,
  Form,
  Field,
  ErrorMessage as FormikErrorMessage,
} from "formik";
import * as Yup from "yup";

import { useGetAllCitiesQuery } from "../../api/cities";
import { useGetAllCityCategoriesQuery } from "../../api/categories";
import { useAddNewSubCategoryMutation } from "../../api/subCategories";

const SubCategoriesAddForm = () => {
  const [buttonText, setButtonText] = useState("Добавить");
  const [buttonClass, setButtonClass] = useState("settings__button");
  const [activeCity, setActiveCity] = useState();
  const [categories, setCategories] = useState([]);

  const {
    data: cities = {},
    isLoading: isLoadingCities,
    isError: isErrorCities,
  } = useGetAllCitiesQuery();

  const { data: categoriesData, isLoading: isLoadingCategories } =
    useGetAllCityCategoriesQuery(
      { city_id: activeCity },
      { skip: !activeCity }
    );

  const [addNewSubCategory] = useAddNewSubCategoryMutation();

  useEffect(() => {
    if (!isLoadingCategories && categoriesData) {
      setCategories(categoriesData);
    }
  }, [categoriesData, isLoadingCategories]);

  const renderCities = () => {
    if (isLoadingCities) {
      return <option>Загрузка...</option>;
    } else if (isErrorCities) {
      return <option>Ошибка!</option>;
    } else {
      return cities.map(({ city_id, city }) => (
        <option key={city_id} value={city_id}>
          {city}
        </option>
      ));
    }
  };

  const renderCategories = () => {
    return categories.map(({ category_id, title }) => (
      <option key={category_id} value={category_id}>
        {title}
      </option>
    ));
  };

  return (
    <section className="employee employee__top">
      <h3 className="employee_title table__title">Добавить новый район</h3>
      <Formik
        initialValues={{ city_id: "", category_id: "", title: "" }}
        validationSchema={Yup.object({
          city_id: Yup.number().required("Это обязательное поле!"),
          category_id: Yup.number().required("Это обязательное поле!"),
          title: Yup.string()
            .max(256, "Максимум 256 символов")
            .required("Это обязательное поле"),
        })}
        onSubmit={async ({ category_id, title }, { resetForm }) => {
          await addNewSubCategory({ category_id, title });
          resetForm();
          setButtonText("Добавлено!");
          setButtonClass("settings__button success");
          setTimeout(() => {
            setButtonText("Сохранить");
            setButtonClass("settings__button");
          }, 500);
        }}
      >
        {(formik) => (
          <Form className="form">
            <div className="form__wrapper">
              <Field
                as="select"
                name="city_id"
                className="form__field"
                onChange={(e) => {
                  const cityId = e.target.value;
                  formik.setFieldValue("city_id", cityId);
                  setActiveCity(cityId || null);
                }}
              >
                <option value="">Выберите город</option>
                {renderCities()}
              </Field>
              <FormikErrorMessage
                component="div"
                name="city_id"
                className="form__error-message"
              />
            </div>
            <div className="form__wrapper">
              <Field
                as="select"
                name="category_id"
                className="form__field"
                disabled={!activeCity}
              >
                <option value="">Выберите категорию</option>
                {renderCategories()}
              </Field>
              <FormikErrorMessage
                component="div"
                name="category_id"
                className="form__error-message"
              />
            </div>
            <div className="form__wrapper">
              <Field
                name="title"
                className="form__field form__text-area"
                as="textarea"
                placeholder="Введите районы с новой строки"
              />
              <FormikErrorMessage
                component="div"
                name="title"
                className="form__error-message"
              />
            </div>
            <button type="submit" className={buttonClass}>
              {buttonText}
            </button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default SubCategoriesAddForm;
