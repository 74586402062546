import "./transactionListItem.scss";

const TransactionListItem = ({ txId, fromWallet, amount }) => {
  return (
    <>
      <li key={txId} className="list-item">
        <div className="list-item__left-block">
          <div className="left-block__content">
            <span className="list-item__title">Перевод</span>
            <span className="list-item__sub-title">C: {fromWallet}</span>
          </div>
        </div>

        <div className="list-item__right-block">
          <p className="list-item__amount">+{amount}LTC</p>
        </div>
      </li>
    </>
  );
};

export default TransactionListItem;
