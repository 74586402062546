import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NotFoundPage from "../../pages/notFoundPage/NotFoundPage";
import DashboardPage from "../../pages/DashboardPage";
import MainPage from "../../pages/mainPage/MainPage";
import SettingsPage from "../../pages/SettingsPage";
import UsersPage from "../../pages/UsersPage";
import SideBar from "../sideBar/SideBar";
import StorePage from "../../pages/StorePage";
import MobileHeader from "../mobileHeader/MobileHeader";
import ModalEditForm from "../modalEditForm/ModalEditForm";
import TransactionPage from "../../pages/transactionPage/TransactionPage";
import ProductAddPage from "../../pages/ProductAddPage";
import PrivateRoute from "../../routes/privateRouter";

import { useAuth } from "../../hooks/useAuth";

const App = () => {
  const { isAuthenticated, userRole } = useAuth();

  return (
    <Router>
      <div className="app">
        {isAuthenticated && userRole === 3 ? <SideBar /> : null}
        {isAuthenticated && userRole === 3 ? <MobileHeader /> : null}
        {isAuthenticated && userRole === 3 ? <ModalEditForm /> : null}

        <main className="containter">
          <Routes>
            <Route path="/:accessKey" element={<MainPage />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <DashboardPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/users"
              element={
                <PrivateRoute>
                  <UsersPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <PrivateRoute>
                  <SettingsPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/goods"
              element={
                <PrivateRoute>
                  <StorePage />
                </PrivateRoute>
              }
            />
            <Route path="/transactions/:wallet" element={<TransactionPage />} />
            <Route path="/addProduct/:accessKey" element={<ProductAddPage />} />
            <Route path="/viewUsers/:accessKey" element={<UsersPage />} />
            <Route path="/404" element={<NotFoundPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
};

export default App;
