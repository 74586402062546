import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { updateAccessKey } from "../features/accessKey";
import { useAuth } from "../hooks/useAuth";
import ProductsAddForm from "../components/productsAddForm/ProductsAddForm";

const ProductAddPage = () => {
  const { accessKey } = useParams();
  const currentAccessKey = useSelector((state) => state.access.accessKey);
  const dispatch = useDispatch();

  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (accessKey && accessKey !== currentAccessKey) {
      dispatch(updateAccessKey(accessKey));
    }
  }, [accessKey, currentAccessKey, dispatch]);

  if (!isAuthenticated) {
    return;
  }

  return (
    <>
      <ProductsAddForm />
    </>
  );
};

export default ProductAddPage;
