import {
  useGetAllCitiesQuery,
  useUpdateCityMutation,
  useDeleteCityMutation,
} from "../../api/cities";

import { PropagateLoader } from "react-spinners";
import ErrorComponent from "../error/Error";
import Table from "../table/Table";

const CitiesTable = () => {
  const { data: cities = {}, isLoading, isError } = useGetAllCitiesQuery();
  const [updateCity] = useUpdateCityMutation();
  const [deleteCity] = useDeleteCityMutation();

  const onDeleteCity = async (city_id) => {
    await deleteCity({ city_id });
  };

  const onUpdateCity = async (e, city_id, city, prevCity) => {
    const value = e.target.value;
    if (value.trim() === "" || city_id === prevCity) {
      e.target.value = prevCity;
    } else {
      await updateCity({ city_id, city });
    }
  };

  if (isLoading) {
    return (
      <div className="spinner__wrapper">
        <PropagateLoader
          className="spinner"
          color={"#9760ad"}
          loading={true}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  } else if (isError) {
    return <ErrorComponent />;
  }

  const renderRows = (cities) => {
    return cities.map(({ city_id, city }) => {
      return (
        <tr key={city_id}>
          <td className="table__content">{city_id}</td>
          <td className="table__content">
            <input
              className="table__input table__large-input"
              type="text"
              defaultValue={city}
              onBlur={(e) => onUpdateCity(e, city_id, city)}
            />
          </td>
          <td className="table__content">
            <button
              onClick={() => onDeleteCity(city_id)}
              className="toggle_button false"
            >
              Удалить
            </button>
          </td>
        </tr>
      );
    });
  };

  const rows = renderRows(cities);
  return (
    <Table
      title={"Пользователи бота"}
      headers={[
        { label: "ID", isHide: false },
        { label: "Город", isHide: false },
        { label: "Действие", isHide: false },
      ]}
      rows={rows}
    />
  );
};

export default CitiesTable;
