import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { backUrl } from "../config";


export const citiesApi = createApi({
  reducerPath: "citiesApi",
  baseQuery: fetchBaseQuery({ baseUrl: backUrl }),
  endpoints: (builder) => ({
    getAllCities: builder.query({
      query: () => "cities",
      providesTags: ["Cities"],
    }),
    addCity: builder.mutation({
      query: ({ city_id, city }) => ({
        url: "cities/new_city",
        method: "POST",
        body: { city_id, city },
      }),
      invalidatesTags: ["Cities"],
    }),
    updateCity: builder.mutation({
      query: ({ city_id, city }) => ({
        url: "cities/update_city",
        method: "POST",
        body: { city_id, city },
      }),
      invalidatesTags: ["Cities"],
    }),
    deleteCity: builder.mutation({
      query: ({ city_id }) => ({
        url: "cities/delete_city",
        method: "POST",
        body: { city_id },
      }),
      invalidatesTags: ["Cities"],
    }),
  }),
});

export const {
  useGetAllCitiesQuery,
  useAddCityMutation,
  useUpdateCityMutation,
  useDeleteCityMutation,
} = citiesApi;
