import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { useAuth } from "../hooks/useAuth";
import { updateAccessKey } from "../features/accessKey";

import PageHeader from "../components/pageHeader/PageHeader";
import SearchPanel from "../components/searchPanel/SearchPanel";
import UserTable from "../components/usersTable/UsersTable";

const UsersPage = () => {
  const { accessKey } = useParams();
  const currentAccessKey = useSelector((state) => state.access.accessKey);
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (accessKey && accessKey !== currentAccessKey) {
      dispatch(updateAccessKey(accessKey));
    }
  }, [accessKey, currentAccessKey, dispatch]);

  if (!isAuthenticated) {
    return;
  }

  return (
    <>
      <PageHeader title="Пользователи" />
      <SearchPanel />
      <UserTable />
    </>
  );
};

export default UsersPage;
