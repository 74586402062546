import { useState } from "react";
import {
  Formik,
  Form,
  Field,
  ErrorMessage as FormikErrorMessage,
} from "formik";
import * as Yup from "yup";

import { useGetAllCitiesQuery } from "../../api/cities";
import { useAddCategoryMutation } from "../../api/categories";

import "./categoryForm.scss";

const CategoriesAddForm = () => {
  const [buttonText, setButtonText] = useState("Добавить");
  const [buttonClass, setButtonClass] = useState("settings__button");

  const [addCategory] = useAddCategoryMutation();
  const { data: cities = {}, isLoading, isError } = useGetAllCitiesQuery();

  const renderCities = () => {
    if (isLoading) {
      return <option>Загрузка городов...</option>;
    } else if (isError) {
      return <option>Произошла ошибка!</option>;
    } else {
      return cities.map(({ city_id, city }) => {
        return (
          <option key={city_id} value={city_id}>
            {city}
          </option>
        );
      });
    }
  };

  return (
    <section className="employee employee__top">
      <h3 className="employee_title table__title">Добавить новый товар</h3>
      <Formik
        initialValues={{
          city_id: "",
          title: "",
          description: "",
          price: "",
          file: "",
        }}
        validationSchema={Yup.object({
          city_id: Yup.number()
            .typeError("Выберите город!")
            .required("Это обязательное поле!"),
          title: Yup.string()
            .max(256, "Максимум 256 символов")
            .required("Это обязательное поле"),
          description: Yup.string()
            .max(512, "Максимум 512 символов")
            .required("Это обязательное поле"),
          price: Yup.number()
            .typeError("Введите целое число!")
            .integer("Должно быть целое число!")
            .required("Это обязательное поле!"),
        })}
        onSubmit={async (
          { city_id, title, description, price },
          { resetForm }
        ) => {
          await addCategory({ city_id, title, description, price });
          resetForm();
          setButtonText("Добавлено!");
          setButtonClass("settings__button success");
          setTimeout(() => {
            setButtonText("Сохранить");
            setButtonClass("settings__button");
          }, 500);
        }}
      >
        <Form className="form">
          <div className="form__wrapper">
            <Field className="form__field" name="city_id" as="select">
              <option>Выберите город</option>
              {renderCities()}
            </Field>
            <FormikErrorMessage
              component="div"
              name="city_id"
              className="form__error-message"
            />
          </div>
          <div className="form__wrapper">
            <Field
              className="form__field"
              name="title"
              placeholder="Введите название товара"
            />
            <FormikErrorMessage
              component="div"
              name="title"
              className="form__error-message"
            />
          </div>
          <div className="form__wrapper">
            <Field
              className="form__field form__text-area"
              name="description"
              as="textarea"
              placeholder="Введите описание товара"
            />
            <FormikErrorMessage
              component="div"
              name="description"
              className="form__error-message"
            />
          </div>
          <div className="form__wrapper">
            <Field
              className="form__field"
              name="price"
              placeholder="Введите цену товара"
            />
            <FormikErrorMessage
              component="div"
              name="price"
              className="form__error-message"
            />
          </div>

          {/* <div className="form__wrapper">
            <Field
              className="form__field form__file"
              type="file"
              name="file"
              accept="image/*"
              multiple
            />
            <FormikErrorMessage
              component="div"
              name="file"
              className="form__error-message"
            />
          </div> */}

          <button type="submit" className={`${buttonClass} `}>
            {buttonText}
          </button>
        </Form>
      </Formik>
    </section>
  );
};

export default CategoriesAddForm;
