import { useState } from "react";
import {
  Formik,
  Form,
  Field,
  ErrorMessage as FormikErrorMessage,
} from "formik";
import * as Yup from "yup";

import { useUpdateUserRoleMutation } from "../../api/users";

import "./employeeAddForm.scss";

const EmployeeAddForm = () => {
  const [updateUserRole] = useUpdateUserRoleMutation();
  const [buttonText, setButtonText] = useState("Добавить");
  const [buttonClass, setButtonClass] = useState("settings__button");

  return (
    <section className="employee">
      <h3 className="employee_title table__title">
        Добавить нового сотрудника
      </h3>
      <Formik
        initialValues={{
          user_id: "",
          role: "",
        }}
        validationSchema={Yup.object({
          user_id: Yup.number()
            .typeError("ID пользователя должно быть числом!")
            .positive("ID пользователя должно быть больше 0!")
            .integer("ID пользователя должно целым числом!")
            .required("Это обязательное поле!"),
          role: Yup.number()
            .typeError("Выберите роль!")
            .required("Это обязательное поле!"),
        })}
        onSubmit={async ({ user_id, role }, { resetForm }) => {
          await updateUserRole({ user_id, value: role });
          resetForm();
          setButtonText("Добавлено!");
          setButtonClass("settings__button success");
          setTimeout(() => {
            setButtonText("Сохранить");
            setButtonClass("settings__button");
          }, 500);

        }}
      >
        <Form className="form">
          <div className="form__wrapper">
            <Field
              className="form__field"
              name="user_id"
              placeholder="Введите TG ID нового сотрудника"
            />
            <FormikErrorMessage
              component="div"
              name="user_id"
              className="form__error-message"
            />
          </div>
          <div className="form__wrapper">
            <Field className="form__field" name="role" as="select">
              <option>Выберите роль сотрудника</option>
              <option value="1">Курьер</option>
              <option value="2">Помощник</option>
              <option value="3">Админ</option>
            </Field>
            <FormikErrorMessage
              component="div"
              name="role"
              className="form__error-message"
            />
          </div>

          <button type="submit" className={`${buttonClass}`}>
            {buttonText}
          </button>
        </Form>
      </Formik>
    </section>
  );
};

export default EmployeeAddForm;
