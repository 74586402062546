import { useState } from "react";
import {
  Formik,
  Form,
  Field,
  ErrorMessage as FormikErrorMessage,
} from "formik";
import * as Yup from "yup";

import {
  useGetMenusTextQuery,
  useUpdateMenusTextMutation,
} from "../../api/settings";

import { PropagateLoader } from "react-spinners";
import ErrorComponent from "../error/Error";

const TextSettingsForm = () => {
  const { data: menusText = {}, isLoading, isError } = useGetMenusTextQuery();
  const [updateMenusText] = useUpdateMenusTextMutation();
  const [buttonText, setButtonText] = useState("Сохранить");
  const [buttonClass, setButtonClass] = useState("settings__button");

  if (isLoading) {
    return (
      <div className="spinner__wrapper">
        <PropagateLoader
          className="spinner"
          color={"#9760ad"}
          loading={true}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  } else if (isError) {
    return <ErrorComponent />;
  }

  const { welcome, rules } = menusText;

  return (
    <section className="settings">
      <Formik
        initialValues={{
          welcome,
          rules,
        }}
        validationSchema={Yup.object({
          welcome: Yup.string()
            .max(4096, "Не больше 4096 символов")
            .required("Это обязательное поле"),
          rules: Yup.string()
            .max(1024, "Не больше 1024 символов")
            .required("Это обязательное поле"),
        })}
        onSubmit={async ({ welcome, rules }) => {
          await updateMenusText({ welcome, rules });
          setButtonText("Сохранено!");
          setButtonClass("settings__button success");
          setTimeout(() => {
            setButtonText("Сохранить");
            setButtonClass("settings__button");
          }, 500);
        }}
      >
        <Form className="settings__form">
          <fieldset className="settings__fieldset settings__fieldset-textarea">
            <legend className="settings__legend">Текста бота</legend>
            <div className="settings__area-wrapper">
              <label htmlFor="log" className="settings__label">
                Текст приветсивя:
              </label>
              <Field
                id="welcome"
                type="text"
                name="welcome"
                as="textarea"
                className="settings__input settings__textarea"
              />
              <FormikErrorMessage
                component="div"
                name="welcome"
                className="employee__form-error"
              />
            </div>

            <div className="settings__area-wrapper">
              <label htmlFor="log" className="settings__label">
                Правила бота
              </label>
              <Field
                id="rules"
                type="text"
                name="rules"
                as="textarea"
                className="settings__input settings__textarea"
              />
              <FormikErrorMessage
                component="div"
                name="rules"
                className="employee__form-error"
              />
            </div>
          </fieldset>

          <button className={buttonClass} type="submit">
            {buttonText}
          </button>
        </Form>
      </Formik>
    </section>
  );
};

export default TextSettingsForm;
