import "./table.scss";

const Table = ({ title = null, headers, rows }) => {
  const renderHeaders = headers.map(({label, isHide}, index) => (
    <th key={index} scope="col" className={`table__head-title ${isHide ? 'table__mobile-hide' : ''}`}>
      {label}
    </th>
  ));

  return (
    <div className="table__wrapper">
      <h3 className="table__title">{title}</h3>
      <table className="table">
        <thead className="table__head">
          <tr className="table__head-row">{renderHeaders}</tr>
        </thead>
        <tbody className="table__body">{rows}</tbody>
      </table>
    </div>
  );
};

export default Table;
