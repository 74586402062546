import "./statistiсLitItem.scss"


const StatistiсListItem = ({ title, subTitle, desciption, imgSrc}) => {
  return (
    <li className="card">
      <div className="card__text-content">
        <h3 className="card__title">{title}</h3>
        <p className="card__sub-title">{subTitle}</p>
        <p className="card__description">{desciption}</p>
      </div>
      <div className="card__diagram">
        <img src={imgSrc} alt="card diagram" />
      </div>
    </li>
  );
};

export default StatistiсListItem;
