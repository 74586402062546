import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { PropagateLoader } from "react-spinners";


const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();


  if (!isAuthenticated ) {
    return (
      <div className="spinner__wrapper">
        <PropagateLoader
          className="spinner"
          color={"#9760ad"}
          loading={true}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  }


  return isAuthenticated ? children : <Navigate to="/404" />;
};

export default PrivateRoute;