import { useDispatch, useSelector } from "react-redux";
// import { useState } from "react";

import burgerOpen from "../../assets/burger-open.png";
import burgClose from "../../assets/burger-close.png";

import { visibleChange } from "../sideBar/sideBarSlice";


import "./mobileHeader.scss";

const MobileHeader = () => {
  const { isVisible } = useSelector((state) => state.sideBar);
  const dispatch = useDispatch();

  return (
    <header className="mobile-header">
      <h1 className="mobile-header__title">WEB Panel</h1>
      <img
        onClick={() => dispatch(visibleChange())}
        src={isVisible ? burgerOpen : burgClose}
        alt="burger-menu"
        width="30"
        height="30"
      />
    </header>
  );
};

export default MobileHeader;
