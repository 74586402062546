import PageHeader from '../components/pageHeader/PageHeader'
import DashboardStatistiсList from "../components/dashboardStatistiсList/DashboardStatistiсList";
import OrdersTable from "../components/ordersTable/OrdersTable";

const DashboardPage = () => {
  return (
    <>
      <PageHeader title={"Dashborad"} />
      <DashboardStatistiсList />
      <OrdersTable />
    </>
  );
};
export default DashboardPage;
