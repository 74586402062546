import { useEffect } from "react";

import TransactionList from "../../components/transactionsList/TransactionList";
import "./transactionPage.scss"

const TransactionPage = () => {
    useEffect(() => {
        document.body.style.backgroundColor = "#1b1b1c";
        
        return () => {
          document.body.style.backgroundColor = "";
        };
      }, []);

  return (
    <>
      <div className="transactions">
        <header className="transactions__header">
          <h1 className="header__title">История транзакций</h1>
        </header>
        <TransactionList />
      </div>
    </>
  );
};

export default TransactionPage;
