import './error.scss';

const ErrorComponent = ({ errorMessage }) => {
    return (
        <div className="error-container">
            <p className="error-message">{errorMessage || 'Произошла ошибка!'}</p>
        </div>
    );
};

export default ErrorComponent;
