import { useState } from "react";
import {
  Formik,
  Form,
  Field,
  ErrorMessage as FormikErrorMessage,
} from "formik";
import * as Yup from "yup";

import { useAddCityMutation } from "../../api/cities";

const CityAddForm = () => {
  const [addCity] = useAddCityMutation();
  const [buttonText, setButtonText] = useState("Добавить");
  const [buttonClass, setButtonClass] = useState("settings__button");

  return (
    <section className="employee employee__top">
      <h3 className="employee_title table__title">Добавить новый город</h3>
      <Formik
        initialValues={{
          city: "",
        }}
        validationSchema={Yup.object({
          city: Yup.string().required("Это обязательное поле!"),
        })}
        onSubmit={async ({ city }, { resetForm }) => {
          await addCity({ city });
          resetForm();
          setButtonText("Добавлено!");
          setButtonClass("settings__button success");
          setTimeout(() => {
            setButtonText("Добавить");
            setButtonClass("settings__button");
          }, 500);
        }}
      >
        <Form className="form">
          <div className="form__wrapper">
            <Field
              className="form__field"
              name="city"
              placeholder="Введите название нового города"
            />
            <FormikErrorMessage
              component="div"
              name="city"
              className="form__error-message"
            />
          </div>
          <button type="submit" className={`${buttonClass}`}>
            {buttonText}
          </button>
        </Form>
      </Formik>
    </section>
  );
};

export default CityAddForm;
