import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Formik,
  Form,
  Field,
  ErrorMessage as FormikErrorMessage,
} from "formik";
import * as Yup from "yup";

import { useGetAllCitiesQuery } from "../../api/cities";
import { useGetAllCityCategoriesQuery } from "../../api/categories";
import { useGetAllSubCategoriesByCategoryQuery } from "../../api/subCategories";
import { useAddNewProductsMutation } from "../../api/products";

const ProductsAddForm = () => {
  const [buttonText, setButtonText] = useState("Добавить");
  const [buttonClass, setButtonClass] = useState("settings__button");
  const [activeCity, setActiveCity] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const accessKey = useSelector((state) => state.access.accessKey);

  const {
    data: cities = {},
    isLoading: isLoadingCities,
    isError: isErrorCities,
  } = useGetAllCitiesQuery();

  const { data: categoriesData, isLoading: isLoadingCategories } =
    useGetAllCityCategoriesQuery({ city_id: activeCity }, {skip: !activeCity});

  const { data: subCategoriesData, isLoading: isLoadingSubCategories } =
    useGetAllSubCategoriesByCategoryQuery({ category_id: activeCategory }, {skip: !activeCategory});

  const [addNewProducts] = useAddNewProductsMutation();

  useEffect(() => {
    if (!isLoadingCategories && categoriesData) {
      setCategories(categoriesData);
    }
  }, [categoriesData, isLoadingCategories]);

  useEffect(() => {
    if (!isLoadingSubCategories && subCategoriesData) {
      setSubCategories(subCategoriesData);
    }
  }, [subCategoriesData, isLoadingSubCategories]);

  const renderCities = () => {
    if (isLoadingCities) {
      return <option>Загрузка...</option>;
    } else if (isErrorCities) {
      return <option>Ошибка!</option>;
    } else {
      return cities.map(({ city_id, city }) => (
        <option key={city_id} value={city_id}>
          {city}
        </option>
      ));
    }
  };

  const renderCategories = () => {
    return categories.map(({ category_id, title }) => (
      <option key={category_id} value={category_id}>
        {title}
      </option>
    ));
  };

  const renderSubCategories = () => {
    return subCategories.map(({ subcategory_id, title }) => (
      <option key={subcategory_id} value={subcategory_id}>
        {title}
      </option>
    ));
  };

  return (
    <section className="employee employee__top">
      <h3 className="employee_title table__title">Добавить новый товар</h3>
      <Formik
        initialValues={{ city_id: "", category_id: "", subcategory_id: "", items: ""}}
        validationSchema={Yup.object({
          city_id: Yup.number()
          .typeError("Выберите город")
          .required("Это обязательное поле!"),
          category_id: Yup.number()
          .typeError("Выберите категорию")
          .required("Это обязательное поле!"),
          subcategory_id: Yup.number()
          .typeError("Выберите район")
          .required("Это обязательное поле!"),
          items: Yup.string().required("Это обязательное поле"),
        })}
        onSubmit={async (
          { category_id, subcategory_id, items },
          { resetForm }
        ) => {
          await addNewProducts({ category_id, subcategory_id, items, accessKey });
          resetForm();
          setButtonText("Добавлено!");
          setButtonClass("settings__button success");
          setTimeout(() => {
            setButtonText("Сохранить");
            setButtonClass("settings__button");
          }, 500);
        }}
      >
        {(formik) => (
          <Form className="form">
            <div className="form__wrapper">
              <Field
                as="select"
                name="city_id"
                className="form__field"
                onChange={(e) => {
                  const cityId = e.target.value;
                  formik.setFieldValue("city_id", cityId);
                  setActiveCity(cityId || null);
                }}
              >
                <option value="">Выберите город</option>
                {renderCities()}
              </Field>
              <FormikErrorMessage
                component="div"
                name="city_id"
                className="form__error-message"
              />
            </div>
            <div className="form__wrapper">
              <Field
                as="select"
                name="category_id"
                className="form__field"
                disabled={!activeCity}
                onChange={(e) => {
                  const categoryId = e.target.value;
                  formik.setFieldValue("category_id", categoryId);
                  setActiveCategory(categoryId || null);
                }}
              >
                <option>Выберите категорию</option>
                {renderCategories()}
              </Field>
              <FormikErrorMessage
                component="div"
                name="category_id"
                className="form__error-message"
              />
            </div>
            <div className="form__wrapper">
              <Field
                as="select"
                name="subcategory_id"
                className="form__field"
                disabled={!activeCategory}
              >
                <option>Выберите район</option>
                {renderSubCategories()}
              </Field>
              <FormikErrorMessage
                component="div"
                name="subcategory_id"
                className="form__error-message"
              />
            </div>
            <div className="form__wrapper">
              <Field
                name="items"
                className="form__field form__text-area"
                as="textarea"
                placeholder="Введите ссылки на адреса с новой строки"
              />
              <FormikErrorMessage
                component="div"
                name="items"
                className="form__error-message"
              />
            </div>
            <button type="submit" className={buttonClass}>
              {buttonText}
            </button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default ProductsAddForm;
