import { useState } from "react";
import { useGetAllOrdersQuery } from "../../api/orders";

import { convertDateTime } from "../../utils/stingConverter";

import { PropagateLoader } from "react-spinners";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";

import ErrorComponent from "../error/Error";
import Table from "../table/Table";

const OrdersTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const { data: orders = {}, isLoading, isError } = useGetAllOrdersQuery();

  const statusData = {
    0: { label: "Ожидание", class: "table__status-pending" },
    1: { label: "Открыт диспут", class: "table__status-dispute" },
    2: { label: "Наход", class: "table__status-success" },
    3: { label: "Ненаход", class: "table__status-unsuccess"},
    4: { label: "Перезаклад", class: "table__status-reissue"},

  };

  if (isLoading) {
    return (
      <div className="spinner__wrapper">
        <PropagateLoader
          className="spinner"
          color={"#9760ad"}
          loading={true}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  } else if (isError) {
    return <ErrorComponent />;
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const to = itemsPerPage * currentPage;
  const from = to - itemsPerPage;
  const currentItems = orders.slice(from, to);

  const renderRows = () => {
    return currentItems.map(
      (
        {
          category_title,
          subcategory_title,
          price,
          status,
          created_at,
          user: { user_id, full_name, username },
        },
        index
      ) => (
        <tr key={index}>
          <td className="table__content">{category_title}</td>
          <td className="table__content">{subcategory_title}</td>
          <td className="table__content">{price}$</td>
          <td className="table__content">{user_id}</td>
          <td className="table__content table__mobile-hide">{full_name}</td>
          <td className="table__content table__mobile-hide">@{username}</td>
          <td className="table__content table__content--status">
            <span className={statusData[status]["class"]}>
              {statusData[status]["label"]}
            </span>
          </td>
          <td className="table__content table__mobile-hide">
            {convertDateTime(created_at)}
          </td>
        </tr>
      )
    );
  };

  return (
    <>
      <Table
        title={"Последние продажи"}
        headers={[
          { label: "Товар", isHide: false },
          { label: "Район", isHide: false },
          { label: "Цена", isHide: false },
          { label: "ID", isHide: false },
          { label: "Name", isHide: true },
          { label: "Username", isHide: true },
          { label: "Статус", isHide: false },
          { label: "Дата и время", isHide: true },
        ]}
        rows={renderRows()}
      />
      <Pagination
        pageSize={itemsPerPage}
        onChange={handlePageChange}
        current={currentPage}
        total={orders.length}
        hideOnSinglePage={true}
        style={{ marginBottom: "60px" }}
      />
    </>
  );
};

export default OrdersTable;
