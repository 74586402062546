import { useState } from "react";

import {
  useGetAllProductsQuery,
  useDeleteProductMutation,
} from "../../api/products";

import { PropagateLoader } from "react-spinners";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";

import ErrorComponent from "../error/Error";
import Table from "../table/Table";

const ProductsTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const { data: products = {}, isLoading, isError } = useGetAllProductsQuery();
  const [deleteProduct] = useDeleteProductMutation();

  if (isLoading) {
    return (
      <div className="spinner__wrapper">
        <PropagateLoader
          className="spinner"
          color={"#9760ad"}
          loading={true}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  } else if (isError) {
    return <ErrorComponent />;
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRequests = async (product_id) => {
    await deleteProduct({ product_id });
  };

  const to = itemsPerPage * currentPage;
  const from = to - itemsPerPage;
  const currentItems = products.slice(from, to);

  const renderRows = () => {
    return currentItems.map(
      ({
        product_id,
        item,
        category: { title: catTitle },
        subcategory: { title: subCatTitle },
      }) => {
        return (
          <tr key={product_id}>
            <td className="table__content">{product_id}</td>
            <td className="table__content">{catTitle}</td>
            <td className="table__content">{subCatTitle}</td>
            <td className="table__content">
              <a target="_blank" rel="noreferrer" href={item}>
                {item}
              </a>
            </td>
            <td className="table__content">
              <button
                onClick={() => handleRequests(product_id)}
                className="toggle_button false"
              >
                Удалить
              </button>
            </td>
          </tr>
        );
      }
    );
  };

  return (
    <>
      <Table
        title={"Адреса кладов"}
        headers={[
          { label: "ID", isHide: false },
          { label: "Категория", isHide: false },
          { label: "Район", isHide: false },
          { label: "Товар", isHide: false },
          { label: "Действие", isHide: false },
        ]}
        rows={renderRows()}
      />
      <Pagination
        pageSize={itemsPerPage}
        onChange={handlePageChange}
        current={currentPage}
        total={products.length}
        hideOnSinglePage={true}
        style={{ marginBottom: "60px" }}
      />
    </>
  );
};

export default ProductsTable;
