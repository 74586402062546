import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import { userApi } from "../api/users";
import { settingsApi } from "../api/settings";
import { citiesApi } from "../api/cities";
import { categoriesApi } from "../api/categories";
import { subCategoriesApi } from "../api/subCategories";
import { productsApi } from "../api/products";
import { ordersApi } from "../api/orders";
import { exchangerApi } from "../api/exchanger";
import { transactionApi } from "../api/transactions";

import filters from "../components/searchPanel/searchPanelSlice";
import settings from "../components/settingsHeader/settingsHeaderSlice";
import sideBar from "../components/sideBar/sideBarSlice";
import modal from "../components/modalEditForm/modalEditFormSlice";
import access from "../features/accessKey";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["access"], // Только access редюсер будет сохраняться и восстанавливаться
};

const persistedReducer = persistReducer(persistConfig, access);

const store = configureStore({
  reducer: {
    filters,
    settings,
    sideBar,
    modal,
    access: persistedReducer,
    [userApi.reducerPath]: userApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [citiesApi.reducerPath]: citiesApi.reducer,
    [categoriesApi.reducerPath]: categoriesApi.reducer,
    [subCategoriesApi.reducerPath]: subCategoriesApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [exchangerApi.reducerPath]: exchangerApi.reducer,
    [transactionApi.reducerPath]: transactionApi.reducer,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "persist/FLUSH",
          "persist/REHYDRATE",
          "persist/PAUSE",
          "persist/PERSIST",
          "persist/PURGE",
          "persist/REGISTER",
        ],
      },
    })
      .concat(userApi.middleware)
      .concat(settingsApi.middleware)
      .concat(citiesApi.middleware)
      .concat(categoriesApi.middleware)
      .concat(subCategoriesApi.middleware)
      .concat(productsApi.middleware)
      .concat(ordersApi.middleware)
      .concat(exchangerApi.middleware)
      .concat(transactionApi.middleware),
});

export const persistor = persistStore(store);

export default store;
