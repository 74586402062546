import './pageHeader.scss'

const PageHeader = ({title}) => {
  return (
    <div className="page-header">
      <h2 className="page-header__title">{title}</h2>
    </div>
  );
};

export default PageHeader;
