// import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";

import {
  useGetAllSubcategoriesQuery,
  useUpdateSubCategoryMutation,
  useDeleteSubCategoryMutation,
} from "../../api/subCategories";

import { PropagateLoader } from "react-spinners";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";

import ErrorComponent from "../error/Error";
import Table from "../table/Table";

const SubCategoriesTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const {
    data: subCategories = {},
    isLoading,
    isError,
  } = useGetAllSubcategoriesQuery();

  const [updateSubCategory] = useUpdateSubCategoryMutation();
  const [deleteSubCategory] = useDeleteSubCategoryMutation();

  const onBlur = (e, subcategory_id, prevValue, type) => {
    const value = e.target.value;
    if (value.trim() === "" || prevValue === value) {
      e.target.value = prevValue;
    } else {
      handleRequests(subcategory_id, value, type);
    }
  };

  const handleRequests = async (subcategory_id, value, type) => {
    switch (type) {
      case "title":
        await updateSubCategory({ subcategory_id, title: value });
        break;

      case "delete":
        await deleteSubCategory({ subcategory_id });
        break;

      default:
        break;
    }
  };

  if (isLoading) {
    return (
      <div className="spinner__wrapper">
        <PropagateLoader
          className="spinner"
          color={"#9760ad"}
          loading={true}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  } else if (isError) {
    return <ErrorComponent />;
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const to = itemsPerPage * currentPage;
  const from = to - itemsPerPage;
  const currentItems = subCategories.slice(from, to);

  const renderRows = () => {
    return currentItems.map(
      ({
        subcategory_id,
        title,
        category: {
          title: catTitle,
          city: { city },
        },
      }) => {
        return (
          <tr key={subcategory_id}>
            <td className="table__content">{subcategory_id}</td>
            <td className="table__content">
              {windowWidth <= 767 ? (
                title
              ) : (
                <input
                  className="table__input"
                  type="text"
                  defaultValue={title}
                  onBlur={(e) => onBlur(e, subcategory_id, title, "title")}
                />
              )}
            </td>
            <td className="table__content">{catTitle}</td>
            <td className="table__content">{city}</td>
            <td className="table__content">
              <button
                onClick={() => handleRequests(subcategory_id, null, "delete")}
                className="toggle_button false"
              >
                Удалить
              </button>
            </td>
          </tr>
        );
      }
    );
  };

  return (
    <>
      <Table
        title={"Районы кладов"}
        headers={[
          { label: "ID", isHide: false },
          { label: "Район", isHide: false },
          { label: "Категория", isHide: false },
          { label: "Город", isHide: false },
          { label: "Действие", isHide: false },
        ]}
        rows={renderRows()}
      />
      <Pagination
        pageSize={itemsPerPage}
        onChange={handlePageChange}
        current={currentPage}
        total={subCategories.length}
        hideOnSinglePage={true}
        style={{ marginBottom: "60px" }}
      />
    </>
  );
};

export default SubCategoriesTable;
