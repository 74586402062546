import { PropagateLoader } from "react-spinners";
import {
  useGetAllEmployeesQuery,
  useUpdateUserRoleMutation,
} from "../../api/users";

import { stringConverter } from "../../utils/stingConverter";

import ErrorComponent from "../error/Error";
import Table from "../table/Table";

const EmployeesTable = () => {
  const {
    data: employees = [],
    isLoading,
    isError,
  } = useGetAllEmployeesQuery();

  const [updateUserRole] = useUpdateUserRoleMutation();

  const roles = { 1: "курьер", 2: "помощник", 3: "админ" };

  const handleRequests = async (user_id, value) => {
    await updateUserRole({ user_id, value });
  };

  if (isLoading) {
    return (
      <div className="spinner__wrapper">
        <PropagateLoader
          className="spinner"
          color={"#9760ad"}
          loading={true}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  } else if (isError) {
    return <ErrorComponent />;
  }

  const renderRows = employees.map(({ user_id, full_name, role }, index) => (
    <tr key={index}>
      <td className="table__content">{user_id}</td>
      <td className="table__content">{stringConverter(full_name)}</td>
      <td className="table__content">{roles[role]}</td>
      <td className="table__content">
        <button
          onClick={() => handleRequests(user_id, 0)}
          className="toggle_button false"
        >
          Удалить
        </button>
      </td>
    </tr>
  ));

  return (
    <Table
      title={"Сотрудники"}
      headers={[
        { label: "ID", isHide: false },
        { label: "Имя", isHide: false },
        { label: "Роль", isHide: false },
        { label: "Действия", isHide: false },
      ]}
      rows={renderRows}
    />
  );
};

export default EmployeesTable;
