import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { backUrl } from "../config";

export const categoriesApi = createApi({
  reducerPath: "categoriesApi",
  baseQuery: fetchBaseQuery({ baseUrl: backUrl }),
  endpoints: (builder) => ({
    getAllCategories: builder.query({
      query: () => "/categories",
      providesTags: ["Categories"],
    }),
    getAllCityCategories: builder.query({
      query: ({ city_id }) => ({
        url: `/categories/city/${city_id}`,
      }),
    }),
    updateCategory: builder.mutation({
      query: ({ category_id, title, price, description }) => ({
        url: "/categories/update",
        method: "POST",
        body: { category_id, title, price, description },
      }),
      invalidatesTags: ["Categories"],
    }),
    updateCategoryTitle: builder.mutation({
      query: ({ category_id, title }) => ({
        url: "/categories/update_title",
        method: "POST",
        body: { category_id, title },
      }),
      invalidatesTags: ["Categories"],
    }),
    updateCategoryDescription: builder.mutation({
      query: ({ category_id, description }) => ({
        url: "/categories/update_description",
        method: "POST",
        body: { category_id, description },
      }),
      invalidatesTags: ["Categories"],
    }),
    updateCategoryPrice: builder.mutation({
      query: ({ category_id, price }) => ({
        url: "/categories/update_price",
        method: "POST",
        body: { category_id, price },
      }),
      invalidatesTags: ["Categories"],
    }),
    addCategory: builder.mutation({
      query: ({ city_id, title, description, price }) => ({
        url: "/categories/new_category",
        method: "POST",
        body: { city_id, title, description, price },
      }),
      invalidatesTags: ["Categories"],
    }),
    deleteCategory: builder.mutation({
      query: ({ category_id }) => ({
        url: "/categories/cat_delete",
        method: "POST",
        body: { category_id },
      }),
      invalidatesTags: ["Categories"],
    }),
  }),
});

export const {
  useGetAllCategoriesQuery,
  useGetAllCityCategoriesQuery,
  useUpdateCategoryMutation,
  useUpdateCategoryTitleMutation,
  useUpdateCategoryDescriptionMutation,
  useUpdateCategoryPriceMutation,
  useAddCategoryMutation,
  useDeleteCategoryMutation,
} = categoriesApi;
