import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

const settingsAdapter = createEntityAdapter();

const initialState = settingsAdapter.getInitialState({
  activeSettings: "",
});

const settingsHeaderSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    activeSettingsChanged: (state, action) => {
      state.activeSettings = action.payload;
    },
  },
});

const { actions, reducer } = settingsHeaderSlice;

export default reducer;

export const { activeSettingsChanged } = actions;
