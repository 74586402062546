import { useParams } from "react-router-dom";

import { useGetWalletTransactionsQuery } from "../../api/transactions";
import { PropagateLoader } from "react-spinners";

import ErrorComponent from "../error/Error";
import TransactionListItem from "../transactionListItem/TransactionListItem";

const TransactionList = () => {
  const { wallet } = useParams();

  const {
    data: transactions = {},
    isLoading,
    isError,
  } = useGetWalletTransactionsQuery({wallet});

  if (isLoading) {
    return (
      <div className="spinner__wrapper">
        <PropagateLoader
          className="spinner"
          color={"#c0c0c0"}
          loading={true}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  } else if (isError) {
    return <ErrorComponent />;
  }

  const renderRows = () => {
    return transactions.map(({ txId, fromWallet, amount }) => {
      return (
        <TransactionListItem
          key={txId}
          txId={txId}
          fromWallet={fromWallet}
          amount={amount}
        />
      );
    });
  };

  return (
    <>
      <ul className="transaction_list">{renderRows()}</ul>
    </>
  );
};

export default TransactionList;
