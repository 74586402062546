import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

const filterAdapter = createEntityAdapter();

const initialState = filterAdapter.getInitialState({
  isVisible: false,
});

const sideBarlSlice = createSlice({
  name: "sideBar",
  initialState,
  reducers: {
    visibleChange: (state) => {
      state.isVisible = !state.isVisible;
    },
  },
});

const { actions, reducer } = sideBarlSlice;

export default reducer;

export const { visibleChange } = actions;
