import { useSelector } from "react-redux";
import SettingsHeader from "../components/settingsHeader/SettingsHeader";
import CitiesTable from "../components/citiesTable/CitiesTable";
import CityAddForm from "../components/cityAddForm/CityAddForm";
import CategoriesTable from "../components/categoriesTable/CategoriesTable";
import CategoriesAddForm from "../components/categoriesAddForm/CategoriesAddForm";
import SubCategoriesTable from "../components/subCategoriesTable/SubCategoriesTable";
import SubCategoriesAddForm from "../components/subCategoriesAddForm/SubCategoriesAddForm";
import ProductsTable from "../components/productsTable/ProductsTable";
import ProductsAddForm from "../components/productsAddForm/ProductsAddForm";
import ErrorComponent from "../components/error/Error";

const StorePage = () => {
  const { activeSettings } = useSelector((state) => state.settings);
  let pageComponent = null;
  switch (activeSettings) {
    case "cities":
      pageComponent = (
        <>
          <CityAddForm />
          <CitiesTable />
        </>
      );
      break;
    case "categories":
      pageComponent = (
        <>
          <CategoriesAddForm />
          <CategoriesTable />
        </>
      );
      break;
    case "subCategories":
      pageComponent = (
        <>
          <SubCategoriesAddForm />
          <SubCategoriesTable />
        </>
      );
      break;
    case "items":
      pageComponent = (
        <>
          <ProductsAddForm />
          <ProductsTable />
        </>
      );
      break;

    default:
      pageComponent = (
        <ErrorComponent errorMessage={"Выберите нужный вам пункт подменю!"} />
      );
  }

  const menuData = [
    { name: "cities", label: "Города" },
    { name: "categories", label: "Категории" },
    { name: "subCategories", label: "Районы" },
    { name: "items", label: "Товары" },
  ];

  return (
    <>
      <SettingsHeader menuData={menuData} />
      {pageComponent}
    </>
  );
};

export default StorePage;
