import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

import { useHttp } from "./http.hook";

import { backUrl } from "../config";

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(false);
  const currentAccessKey = useSelector((state) => state.access.accessKey);
  const { request } = useHttp();

  useEffect(() => {
    const checkAuth = async () => {
      if (!currentAccessKey) {
        setIsAuthenticated(false);
        return;
      }

      try {
        const data = await request(`${backUrl}auth/${currentAccessKey}`);
        setIsAuthenticated(data.isAuthenticated);
        setUserRole(data.role);
      } catch (error) {
        setIsAuthenticated(false);
      }
    };

    checkAuth();
  }, [currentAccessKey, request]);

  return { isAuthenticated, userRole };
};
