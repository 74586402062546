import { useMemo, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  useGetAllUsersQuery,
  useUpdateUserBalanceMutation,
  useUpdateUserDiscountMutation,
  useUpdateUserVipMutation,
  useUpdateUserBanMutation,
} from "../../api/users";

import { PropagateLoader } from "react-spinners";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";

import ErrorComponent from "../error/Error";
import Table from "../table/Table";

import { stringConverter } from "../../utils/stingConverter";

import "./userTable.scss";

const UserTable = () => {
  const accessKey = useSelector((state) => state.access.accessKey);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const {
    data: users = [],
    isLoading,
    isError,
    refetch,
  } = useGetAllUsersQuery();
  const [updateUserBalance] = useUpdateUserBalanceMutation();
  const [updateUserDiscount] = useUpdateUserDiscountMutation();
  const [updateUserVip] = useUpdateUserVipMutation();
  const [updateUserBan] = useUpdateUserBanMutation();
  const { activeFilter } = useSelector((state) => state.filters);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 15000);

    return () => clearInterval(interval);
  }, [refetch]);

  const filteredUsers = useMemo(() => {
    const filteredUsers = users.slice();
    if (activeFilter === "all") {
      return filteredUsers;
    } else if (activeFilter === "vip") {
      return filteredUsers.filter((user) => user.is_vip);
    } else if (activeFilter === "discount") {
      return filteredUsers.filter((user) => user.discount > 0);
    } else if (activeFilter === "banned") {
      return filteredUsers.filter((user) => user.is_banned);
    } else {
      return filteredUsers.filter(
        (user) => user.user_id.toString().indexOf(activeFilter) > -1
      );
    }
    //eslint-disable-next-line
  }, [users, activeFilter]);

  const toggleStatus = (user_id, status, type) => {
    handleRequests(user_id, !status, type);
  };

  const onBlur = (e, user_id, prevValue, type) => {
    const value = e.target.value;
    if (
      value.trim() === "" ||
      isNaN(Number(value)) ||
      prevValue === Number.parseInt(value)
    ) {
      e.target.value = prevValue;
    } else {
      handleRequests(user_id, value, type);
    }
  };

  const handleRequests = async (user_id, value, type) => {
    switch (type) {
      case "balance":
        await updateUserBalance({ user_id, value, accessKey });
        break;
      case "discount":
        await updateUserDiscount({ user_id, value });
        break;

      case "vip":
        await updateUserVip({ user_id, value });
        break;

      case "ban":
        await updateUserBan({ user_id, value });
        break;

      default:
        break;
    }
  };

  if (isLoading) {
    return (
      <div className="spinner__wrapper">
        <PropagateLoader
          className="spinner"
          color={"#9760ad"}
          loading={true}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  } else if (isError) {
    return <ErrorComponent />;
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const to = itemsPerPage * currentPage;
  const from = to - itemsPerPage;
  const currentItems = filteredUsers.slice(from, to);

  const renderRows = (users) => {
    return users.map(
      ({
        user_id,
        full_name,
        username,
        balance,
        discount,
        is_vip,
        is_banned,
        order,
      }) => {
        return (
          <tr key={user_id}>
            <td className="table__content">{user_id}</td>
            <td className="table__content table__mobile-hide">
              {stringConverter(full_name)}
            </td>
            <td className="table__content table__mobile-hide">
              @{stringConverter(username)}
            </td>
            <td className="table__content">
              <input
                className="table__input"
                type="text"
                defaultValue={balance}
                onBlur={(e) => onBlur(e, user_id, balance, "balance")}
              />
            </td>
            <td className="table__content">
              <input
                className="table__input"
                type="text"
                defaultValue={discount}
                onBlur={(e) => onBlur(e, user_id, discount, "discount")}
              />
            </td>

            <td className="table__content">
              <button
                onClick={() => toggleStatus(user_id, is_vip, "vip")}
                className={`toggle_button ${is_vip ? "true" : "false"}`}
              >
                {is_vip ? "True" : "False"}
              </button>
            </td>
            <td className="table__content">
              <button
                onClick={() => toggleStatus(user_id, is_banned, "ban")}
                className={`toggle_button ${is_banned ? "true" : "false"}`}
              >
                {is_banned ? "True" : "False"}
              </button>
            </td>
            <td className="table__content table__mobile-hide">
              {order.length}
            </td>
          </tr>
        );
      }
    );
  };

  return (
    <>
      <Table
        title={"Пользователи бота"}
        headers={[
          { label: "ID", isHide: false },
          { label: "Name", isHide: true },
          { label: "Username", isHide: true },
          { label: "Баланс($)", isHide: false },
          { label: "Скидка(%)", isHide: false },
          { label: "VIP", isHide: false },
          { label: "Бан", isHide: false },
          { label: "Покупок", isHide: true },
        ]}
        rows={renderRows(currentItems)}
      />
      <Pagination
        pageSize={itemsPerPage}
        onChange={handlePageChange}
        current={currentPage}
        total={filteredUsers.length}
        hideOnSinglePage={true}
        style={{ marginBottom: "60px" }}
      />
    </>
  );
};

export default UserTable;
