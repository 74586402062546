import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { backUrl } from "../config";


export const ordersApi = createApi({
  reducerPath: "ordersApi",
  baseQuery: fetchBaseQuery({ baseUrl: backUrl }),
  endpoints: (builder) => ({
    getAllOrders: builder.query({
      query: () => "orders",
      providesTags: ["Orders"],
    }),
    getSalesStat: builder.query({
      query: () => "orders/stats",
    }),
  }),
});

export const { useGetAllOrdersQuery, useGetSalesStatQuery } = ordersApi;
