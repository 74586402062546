import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { backUrl } from "../config";


export const productsApi = createApi({
  reducerPath: "productsApi",
  baseQuery: fetchBaseQuery({ baseUrl: backUrl }),
  endpoints: (builder) => ({
    getAllProducts: builder.query({
      query: () => "products",
      providesTags: ["Products"],
    }),
    deleteProduct: builder.mutation({
      query: ({ product_id }) => ({
        url: "/products/delete_item",
        method: "POST",
        body: { product_id },
      }),
      invalidatesTags: ["Products"],
    }),
    addNewProducts: builder.mutation({
      query: ({ category_id, subcategory_id, items, accessKey }) => ({
        url: "products/new_item",
        method: "POST",
        body: { category_id, subcategory_id, items, accessKey },
      }),
      invalidatesTags: ["Products"],
    }),
  }),
});

export const {
  useGetAllProductsQuery,
  useDeleteProductMutation,
  useAddNewProductsMutation,
} = productsApi;
