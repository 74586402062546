import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

const accessAdapter = createEntityAdapter();

const initialState = accessAdapter.getInitialState({
  accessKey: "",
});

const accessSlice = createSlice({
  name: "access",
  initialState,
  reducers: {
    updateAccessKey: (state, action) => {
      state.accessKey = action.payload;
    },
  },
});

const { actions, reducer } = accessSlice;

export default reducer;

export const { updateAccessKey } = actions;
