import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

const filterAdapter = createEntityAdapter();

const initialState = filterAdapter.getInitialState({
  activeFilter: "all",
});

const searchPanelSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    activeFilterChanged: (state, action) => {
      if (action.payload === "") {
        state.activeFilter = "all";
      } else {
        state.activeFilter = action.payload
      }
    },
  },
});

const { actions, reducer } = searchPanelSlice;

export default reducer;

export const { activeFilterChanged } = actions;
