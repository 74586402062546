import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import {
  useGetAllCategoriesQuery,
  useUpdateCategoryTitleMutation,
  useUpdateCategoryDescriptionMutation,
  useUpdateCategoryPriceMutation,
  useDeleteCategoryMutation,
} from "../../api/categories";

import { openModal } from "../modalEditForm/modalEditFormSlice";
import { PropagateLoader } from "react-spinners";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";

import ErrorComponent from "../error/Error";

import Table from "../table/Table";

const CategoriesTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const {
    data: categories = {},
    isLoading,
    isError,
  } = useGetAllCategoriesQuery();
  const [updateCategoryTitle] = useUpdateCategoryTitleMutation();
  const [updateCategoryDescription] = useUpdateCategoryDescriptionMutation();
  const [updateCategoryPrice] = useUpdateCategoryPriceMutation();
  const [deleteCategory] = useDeleteCategoryMutation();

  const onBlur = (e, category_id, prevValue, type) => {
    const value = e.target.value;
    if (value.trim() === "" || prevValue === value) {
      e.target.value = prevValue;
    } else {
      handleRequests(category_id, value, type);
    }
  };

  const handleRequests = async (category_id, value, type) => {
    switch (type) {
      case "title":
        await updateCategoryTitle({ category_id, title: value });
        break;
      case "description":
        await updateCategoryDescription({ category_id, description: value });
        break;

      case "price":
        await updateCategoryPrice({ category_id, price: value });
        break;

      case "delete":
        await deleteCategory({ category_id });
        break;

      default:
        break;
    }
  };

  if (isLoading) {
    return (
      <div className="spinner__wrapper">
        <PropagateLoader
          className="spinner"
          color={"#9760ad"}
          loading={true}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  } else if (isError) {
    return <ErrorComponent />;
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const to = itemsPerPage * currentPage;
  const from = to - itemsPerPage;
  const currentItems = categories.slice(from, to);

  const renderRows = (categories) => {
    return currentItems.map(
      ({
        category_id,
        title,
        description,
        price,
        image_path,
        city: { city },
      }) => {
        return (
          <tr key={category_id}>
            <td className="table__content">{category_id}</td>
            <td className="table__content">
              {windowWidth <= 767 ? (
                title
              ) : (
                <input
                  className="table__input table__large-input"
                  type="text"
                  defaultValue={title}
                  onBlur={(e) => onBlur(e, category_id, title, "title")}
                />
              )}
            </td>
            <td className="table__content">{city}</td>
            <td className="table__content table__mobile-hide">
              <input
                className="table__input"
                type="text"
                defaultValue={price}
                onBlur={(e) => onBlur(e, category_id, price, "price")}
              />
            </td>
            <td className="table__content">
              <button
                onClick={() => handleRequests(category_id, null, "delete")}
                className="toggle_button false"
              >
                Удалить
              </button>
            </td>
            <td className="table__content">
              <button
                onClick={() => dispatch(openModal(category_id))}
                className="toggle_button change"
              >
                Изменить
              </button>
            </td>
          </tr>
        );
      }
    );
  };

  return (
    <>
      <Table
        title={"Категории бота"}
        headers={[
          { label: "ID", isHide: false },
          { label: "Категория", isHide: false },
          { label: "Город", isHide: false },
          { label: "Цена ($)", isHide: true },
          { label: "Удаления", isHide: false },
          { label: "Изменения", isHide: false },
        ]}
        rows={renderRows(categories)}
      />
      <Pagination
        pageSize={itemsPerPage}
        onChange={handlePageChange}
        current={currentPage}
        total={categories.length}
        hideOnSinglePage={true}
        style={{ marginBottom: "60px" }}
      />
    </>
  );
};

export default CategoriesTable;
