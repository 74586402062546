import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { backUrl } from "../config";


export const subCategoriesApi = createApi({
  reducerPath: "subCategoriesApi",
  baseQuery: fetchBaseQuery({ baseUrl: backUrl }),
  endpoints: (builder) => ({
    getAllSubcategories: builder.query({
      query: () => "subcategories",
      providesTags: ["Subcategories"],
    }),
    getAllSubCategoriesByCategory: builder.query({
      query: ({ category_id }) => ({
        url: `subcategories/${category_id}`,
      }),
    }),
    updateSubCategory: builder.mutation({
      query: ({ subcategory_id, title }) => ({
        url: "subcategories/subcat_update",
        method: "POST",
        body: { subcategory_id, title },
      }),
      invalidatesTags: ["Subcategories"],
    }),
    deleteSubCategory: builder.mutation({
      query: ({ subcategory_id }) => ({
        url: "subcategories/subcat_delete",
        method: "POST",
        body: { subcategory_id },
      }),
      invalidatesTags: ["Subcategories"],
    }),
    addNewSubCategory: builder.mutation({
      query: ({ category_id, title }) => ({
        url: "subcategories/new_subcat",
        method: "POST",
        body: { category_id, title },
      }),
      invalidatesTags: ["Subcategories"],
    }),
  }),
});

export const {
  useGetAllSubcategoriesQuery,
  useGetAllSubCategoriesByCategoryQuery,
  useUpdateSubCategoryMutation,
  useDeleteSubCategoryMutation,
  useAddNewSubCategoryMutation,
} = subCategoriesApi;
