import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { backUrl } from "../config";

export const transactionApi = createApi({
  reducerPath: "transactionApi",
  baseQuery: fetchBaseQuery({ baseUrl: backUrl }),
  endpoints: (builder) => ({
    getWalletTransactions: builder.query({
      query: ({ wallet }) => ({
        url: `transactions/wallet/${wallet}`,
      }),
      providesTags: ["Transactions"],
    }),
  }),
});

export const { useGetWalletTransactionsQuery } = transactionApi;
