import { useDispatch, useSelector } from "react-redux";
import { activeSettingsChanged } from "./settingsHeaderSlice";

import "./settingsHeader.scss";

const SettingsHeader = ({ menuData }) => {
  const { activeSettings } = useSelector((state) => state.settings);

  const dispatch = useDispatch();

  const menus = menuData.map(({ name, label }, index) => (
    <li
      className={`settings-header__list-item ${
        name === activeSettings ? "settings-header__list-item--selected" : ""
      }`}
      key={index}
      onClick={() => dispatch(activeSettingsChanged(name))}
    >
      {label}
    </li>
  ));

  return (
    <header className="settings-header">
      <nav className="settings-header__navigation">
        <ul className="settings-header__list">{menus}</ul>
      </nav>
    </header>
  );
};

export default SettingsHeader;
