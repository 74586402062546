import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { Link } from "react-router-dom";

import { visibleChange } from "./sideBarSlice";

import dashboard from "./assets/dashboard.svg";
import users from "./assets/users.svg";
// import payments from "./assets/payments.svg";
import goods from "./assets/goods.svg";
import settings from "./assets/settings.svg";
// import logout from "./assets/logout.svg";

import "./sidebar.scss";

const SideBar = () => {
  const { isVisible } = useSelector((state) => state.sideBar);
  const [activeMenu, setActiveMenu] = useState(null);
  const dispatch = useDispatch();

  const menuItems = [
    { title: "Dashboard", imgSrc: dashboard, path: "/dashboard" },
    { title: "Пользователи", imgSrc: users, path: "/users" },
    // { title: "Платежи", imgSrc: payments, path: "/payments" },
    { title: "Витрина", imgSrc: goods, path: "/goods" },
    { title: "Настройки", imgSrc: settings, path: "/settings" },
    // { title: "Выйти", imgSrc: logout, path: "/logout" },
  ];

  const handleLinkClick = (index) => {
    setActiveMenu(index);
    dispatch(visibleChange());
  };

  const menus = menuItems.map(({ title, imgSrc, path }, index) => (
    <li
      className={`sidebar__navigation-item ${
        index === activeMenu ? "sidebar__navigation-item--selected" : ""
      }`}
      key={index}
    >
      <img src={imgSrc} alt="side bar menu icon" />
      <Link
        to={path}
        className="sidebar__link"
        onClick={() => handleLinkClick(index)}
      >
        {title}
      </Link>
    </li>
  ));

  return (
    <aside className={`sidebar ${isVisible ? "sidebar__visible" : ""}`}>
      <h1 className="sidebar__title">
        <Link to={"/"} onClick={() => setActiveMenu(null)}>
          WEB PANEL
        </Link>
      </h1>
      <nav className="sidebar__navigation">
        <ul className="sidebar__navigation-list">{menus}</ul>
      </nav>
    </aside>
  );
};

export default SideBar;
