import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Formik,
  Form,
  Field,
  ErrorMessage as FormikErrorMessage,
} from "formik";
import * as Yup from "yup";
import { openModal } from "./modalEditFormSlice";

import { useUpdateCategoryMutation, useGetAllCategoriesQuery } from "../../api/categories";
import { backUrl } from "../../config";

import "./modalEditForm.scss";

const ModalEditForm = () => {
  const [buttonText, setButtonText] = useState("Сохранить");
  const [buttonClass, setButtonClass] = useState("modal__button--save");

  const dispatch = useDispatch();
  const { isClose } = useSelector((state) => state.modal);
  const { activeCategory } = useSelector((state) => state.modal);
  const [initialValues, setInitialValues] = useState({
    category_id: "",
    title: "",
    price: "",
    description: "",
  });
  const { refetch } = useGetAllCategoriesQuery();
  const [updateCategory] = useUpdateCategoryMutation();

  useEffect(() => {
    if (activeCategory) {
      fetch(
        `${backUrl}categories/category/${activeCategory}`
      )
        .then((response) => response.json())
        .then((data) => {
          setInitialValues({
            category_id: data.category_id || "",
            title: data.title || "",
            price: data.price || "",
            description: data.description || "",
          });
        })
        .catch((error) => {
          console.error("Error fetching category data:", error);
          setInitialValues({
            category_id: "",
            title: "",
            price: "",
            description: "",
          });
        });
    } else {
      setInitialValues({
        category_id: "",
        title: "",
        price: "",
        description: "",
      });
    }
  }, [activeCategory]);

  return (
    <div className={`modal ${isClose ? "modal__close" : ""}`}>
      <div className="modal__content">
        <h2 className="modal__title">Изменение категории</h2>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={Yup.object({
            title: Yup.string().required("Это обязательное поле"),
            description: Yup.string().required("Это обязательное поле"),
            price: Yup.number()
              .typeError("Введите целое число!")
              .integer("Должно быть целое число!")
              .required("Это обязательное поле!"),
          })}
          onSubmit={async ({ category_id, title, price, description }) => {
            await updateCategory({
              category_id,
              title,
              price,
              description,
            });
            refetch()

            setButtonText("Сохранено!");
            setButtonClass("modal__button--saved");
            setTimeout(() => {
              setButtonText("Сохранить");
              setButtonClass("modal__button--save");
            }, 500);
          }}
        >
          <Form className="modal__form">
            <div className="form__wrapper">
              <label htmlFor="category_id" className="modal__label">
                ID категории:
              </label>
              <Field
                id="category_id"
                name="category_id"
                type="text"
                className="modal__input"
                readOnly
              />
            </div>
            <div className="form__wrapper">
              <label htmlFor="title" className="modal__label">
                Категория:
              </label>
              <Field
                id="title"
                name="title"
                type="text"
                className="modal__input"
              />
              <FormikErrorMessage
                className="form__error-message"
                component="div"
                name="title"
              />
            </div>
            <div className="form__wrapper">
              <label htmlFor="price" className="modal__label">
                Цена ($):
              </label>
              <Field
                id="price"
                name="price"
                type="text"
                className="modal__input"
              />
              <FormikErrorMessage
                className="form__error-message"
                component="div"
                name="price"
              />
            </div>
            <div className="form__wrapper">
              <label htmlFor="description" className="modal__label">
                Описание
              </label>
              <Field
                as="textarea"
                name="description"
                id="description"
                cols="30"
                rows="10"
                className="modal__text-area"
              ></Field>
              <FormikErrorMessage
                className="form__error-message"
                component="div"
                name="description"
              />
            </div>
            <button className={`modal__button ${buttonClass}`} type="submit">
              {buttonText}
            </button>
          </Form>
        </Formik>
        <button
          className="modal__button modal__button--close"
          onClick={() => dispatch(openModal(null))}
        >
          Закрыть
        </button>
      </div>
    </div>
  );
};

export default ModalEditForm;
